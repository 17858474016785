import React, { Children, Component } from 'react'
import { MDBTypography, MDBCol, MDBRow, MDBCollapse, MDBContainer, MDBCard, MDBCardHeader, MDBCardBody, MDBIcon} from 'mdbreact';
import './aboutMe.css'

class AboutMe extends Component {
    

    render() {
    return (
      <>
        <MDBRow id="aboutMeHeader" className="py-5">
          <MDBCol>
            <MDBContainer className="my-5">
            
            <h1>
            <small><u>LIFE, LOVE and being a</u>
            <br/>
            MARIAGE CELEBRANT</small>
            </h1>
            
            </MDBContainer>
          </MDBCol>
              
        </MDBRow>
        <MDBContainer>

        </MDBContainer>
      </>
        );
    }
}
      
export default AboutMe;
